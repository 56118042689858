export const useBoardShare = () => {
  const sharedBoard = useState<BoardItem>("board-share-item");
  const isShareOpen = useState<boolean>("board-share-open", () => false);

  const shareBoard = (board: MaybeRef<BoardItem>) => {
    sharedBoard.value = unref(board);
    nextTick(() => {
      isShareOpen.value = true;
    });
  };

  const closeShare = () => {
    isShareOpen.value = false;
  };

  const runtimeConfig = useRuntimeConfig();

  const getShareLink = (
    type: "messenger" | "whatsapp" | "facebook" | "email" | "sms",
    url: string,
    title?: string
  ) => {
    switch (type) {
      case "messenger":
        return (
          `https://www.facebook.com/dialog/send?` +
          [
            `link=${encodeURIComponent(url)}`,
            `app_id=${encodeURIComponent(runtimeConfig.public.facebook.appId)}`,
            `redirect_uri=${encodeURIComponent(url)}`,
          ].join("&")
        );

      case "whatsapp":
        return (
          "https://wa.me/?text=" +
          encodeURIComponent((title ? `${title}\n\n` : "") + `${url}`)
        );

      case "facebook":
        return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`;

      case "email":
        return (
          "mailto:?" +
          (title ? `subject=${encodeURIComponent(title)}&` : "") +
          `body=${encodeURIComponent(url)}`
        );

      case "sms":
        return (
          "sms:?&body=" +
          encodeURIComponent((title ? `${title}\n\n` : "") + `${url}`)
        );
    }
  };

  return {
    sharedBoard,
    isShareOpen,
    shareBoard,
    closeShare,
    getShareLink,
  };
};
